@font-face {
  font-family: 'Stereo Gothic';
  src: local('Stereo Gothic'), url(./assets/stereoGothic400.ttf) format('truetype');
}

@font-face {
  font-family: 'Lexend Exa';
  src: local('Lexend Exa'), url(./assets/LexendExa-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
