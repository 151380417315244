.ANSI__container {
    display: flex;
    justify-content: space-between;
}

.edit__ANSI__container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.footer__editANSI {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -5px;
}